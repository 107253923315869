import React, { useState } from 'react'
import Menu from '../../molecules/Menu'
import Footer from '../../molecules/Footer'
import * as Styled from './styles'
import NovoCadastro from '../NovoCadastro'

const CadastroSelect = () => {
  const [selectedCity, setSelectedCity] = useState(null)

  const handleSelection = (city) => {
    setSelectedCity(city)
  }

  return (
    <>
      <Menu />
      <Styled.Container>
        <Styled.Content>
          <Styled.MainQuestion>Qual sua cidade?</Styled.MainQuestion>
          <Styled.Button
            onClick={() => handleSelection('joao-pessoa')}
            selected={selectedCity === 'joao-pessoa'}
          >
            João Pessoa - PB
          </Styled.Button>
          <Styled.Button
            onClick={() => handleSelection('campina-grande')}
            selected={selectedCity === 'campina-grande'}
          >
            Campina Grande - PB
          </Styled.Button>
          <Styled.Button
            onClick={() => handleSelection('natal')}
            selected={selectedCity === 'natal'}
          >
            Natal - RN
          </Styled.Button>
          <Styled.Button
            onClick={() => handleSelection('outra')}
            selected={selectedCity === 'outra'}
          >
            Outra?
          </Styled.Button>

          {selectedCity === 'outra' && (
            <div>
              <p>Ainda não funcionamos com nossa logística em sua cidade</p>
              <p>
                Mas tenho uma boa notícia para você: com a Moovery, você pode vincular entregadores
                e utilizar nossa plataforma para gerenciar seus entregadores no seu estabelecimento!
              </p>
              <p>Quer saber mais?</p>
              <NovoCadastro selectedCity="outra" />
            </div>
          )}

          {selectedCity && selectedCity !== 'outra' && <NovoCadastro selectedCity={selectedCity} />}
        </Styled.Content>
      </Styled.Container>
      <Footer />
    </>
  )
}

export default CadastroSelect
